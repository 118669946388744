<template>
    <div data-scroll :data-scroll-id="name" class="layout-4">
        <img
            src="@/assets/images/brand-background.png"
            class="background-image"
            alt=""
        />
        <div v-observer :class="{ dark: !lightMode }" class="content-container">
            <div class="item" v-for="(item, index) in items" :key="index">
                <div
                    class="header"
                    v-html="item.title"
                    :style="{ color: item.color }"
                    v-if="item.title !== null"
                ></div>
                <div class="description" v-html="item.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import first from "lodash/first";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {},
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        secondItemSpeed() {
            return this.device.size < 1025 ? 0 : 2;
        },
        firstItemSpeed() {
            return this.device.size < 1025 ? 0 : 0.5;
        },
        items() {
            return first(
                this.data?.list?.map(item =>
                    item?.content?.tabs?.map((cont, index) => ({
                        title: cont.title ? cont.title : null,
                        content: cont.content,
                        color:
                            index === 0
                                ? cont.color?.color || cont.color1
                                : "transparent"
                    }))
                )
            );
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.layout-4 /deep/ {
    padding: 144px 195px 0;
    position: relative;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 144px 165px 0;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 144px 128px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding: 144px 47px 0;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 89px 47px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 16px 0;
    }

    .background-image {
        position: absolute;
        width: 100%;
        opacity: 0.1;
        pointer-events: none;
        left: 0;
        top: 0;
    }

    .content-container {
        display: flex;
        align-items: flex-start;
        text-align: left;
        grid-gap: 30px;

        @media only screen and (max-width: 1023px) {
            flex-direction: column;
        }

        &.dark {
            .description {
                h1,
                h2,
                h3,
                p,
                span,
                div {
                    color: white !important;
                }
            }
        }

        .item {
            flex: 50%;
            flex-direction: column;
            display: flex;
            @media only screen and (max-width: 1023px) {
                &:nth-child(2) {
                    .header {
                        display: none;
                    }

                    .description {
                        margin-top: 0;
                    }
                }
            }

            .header {
                display: block;
                text-align: left;
                font-weight: bold;
                font-size: 34px;

                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .description {
                margin-top: 30px;
                font-weight: normal;
                word-break: keep-all;
                display: block;

                @media only screen and (max-width: 767px) {
                    margin-top: 20px;
                }

                h1,
                h2,
                h3,
                p,
                span,
                div {
                    font-weight: normal;
                    color: #0a141c;
                    font-size: 21px;
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                    transition: color 0.4s $ease-out;
                }
            }
        }
    }
}
</style>
